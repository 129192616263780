import React from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import i18next from "i18next";

import {IValidationState} from "../redux/reducers";
import {ValidationActionCreators} from "../redux/actions";
import {getError, getValidation} from "../redux/selectors";
import {getRouteByMenuKey, getDataBySectionAndWidget, getLivrasionEstimate} from "../../../helpers/helpers";

import "./validation.scss";
import {BASE_API_GATEWAY} from "../../../utils/constants/service";
import {Link, Redirect} from "react-router-dom";
import {getMenu, getUser} from "../../../layouts/main/header/redux/selectors";
import {ORDER_STATUS, STORE_DELIVERY, STORE_DEPOT} from "../../../utils/constants/variables";
import {getUserStore} from "../../location/redux/selectors";
import { AddToCartActionCreators } from "../../addToCart/redux/actions";


interface Props extends IValidationState {
    data: any,
    error: any,
    validationPage: any,
    menuList: any,
    user: any,
    userStore: any,
}

interface State {
    isVisible: boolean
}

class ValidationPaypal extends React.Component<Props & typeof ValidationActionCreators> {

    state: State = {
        isVisible: false
    };

    componentDidMount() {
        const { history: { location }, user } = this.props;
        const { state: { payPalData = {}, BaskstStore = {} } = {} } = location;
        const { createdOrderId, paypalStatus } = payPalData;
        const selectedStore = BaskstStore && BaskstStore["storeDelivery"] ? BaskstStore["storeDelivery"] : "";
        const storeType = BaskstStore && BaskstStore["StoreInfo"] && BaskstStore["StoreInfo"]["storeType"];

        if (paypalStatus && createdOrderId) {
            let orderStatus = "PAI";
            if (selectedStore !== 'DIS' || storeType !== 'E') {
                if (paypalStatus === "accepted") {
                    if (selectedStore) {
                        if (STORE_DEPOT === "1" && STORE_DELIVERY === "0") {
                            orderStatus = "DEP"
                        } else if (!(STORE_DEPOT === "1" && STORE_DELIVERY === "1")) {
                            orderStatus = selectedStore;
                        }
                    }
                    localStorage.removeItem('isAnulled');
                    this.props.destroyBasketStart();
                } else {
                    orderStatus = "FAI"
                }
            }

            if (orderStatus === ORDER_STATUS.PAI || orderStatus === ORDER_STATUS.DIS) {
                this.setState({isVisible: true})
            }
            const awinAwc = localStorage.getItem('awinAwc')
            const isApprovedAwc = awinAwc && !this.props.error && (orderStatus === 'DIS' || orderStatus === 'PAI' || orderStatus === 'STO')
            this.props.updateOrderStart({
                orderId: createdOrderId, 
                orderStatus, 
                email: user.email,
                ...(isApprovedAwc && { awinAwc })
            })
            if (isApprovedAwc) {
                localStorage.removeItem('awinAwc');
                localStorage.removeItem('awinCampaign');
            }
        }

        if (this.props.validationPage && this.props.validationPage.gridId) {
            this.props.validationStart(this.props.validationPage.gridId);
        }
    }

    render() {
        const {isVisible} = this.state
        const {data, menuList, history: {location: {state = {}} = {}}, userStore} = this.props;
        const {payPalData = {}} = state;
        const selectedStore = state && state.BaskstStore && state.BaskstStore["StoreInfo"]
        const storeDelivery = state && state.BaskstStore && state.BaskstStore["storeDelivery"]
        const {paymentID, createdOrderId, paypalStatus, reference} = payPalData;
        // if (!createdOrderId || !paypalStatus) {
        //     return <Redirect to='/'/>
        // }
        const store = selectedStore ? selectedStore : userStore;
        return (
            <div>
                <div className="small-container">
                    <div className="validation">
                        <h2>{paypalStatus === "accepted" ? i18next.t('validation.1') : i18next.t('validation.15')}</h2>
                        <h4 className="validation-title">{paypalStatus === "accepted" ? i18next.t('validation.2') : i18next.t('validation.16')}</h4>
                        <h4>{paypalStatus === "accepted" ? i18next.t('validation.3') : i18next.t('validation.17')}</h4>
                        <p>{i18next.t('validation.4')} : <span
                            className="bold-text">{createdOrderId}</span></p>

                        {
                            paymentID ?
                                <p>{i18next.t('validation.13')} : <span className="bold-text"> {paymentID} </span>
                                </p> : ""
                        }
                        {reference && <p>{i18next.t('validation.14')} : <span
                            className="bold-text">{reference}</span></p>}
                        {isVisible && <p>{i18next.t('payment.16')}: <span className="livrasion-estimate-text">{getLivrasionEstimate()}</span></p>}
                        {store && (storeDelivery === "STO" || storeDelivery === "DEP") ?
                            <>
                                <h4>{i18next.t('validation.18')}</h4>
                                <div className="validation storeDetals">
                                    <p className="storeName">{store.name}</p>
                                    <p>{store.address}</p>
                                    <p>{store.zip}</p>
                                    {store.phone ? <p>Tél: {store.phone}</p> : ""}
                                </div>
                            </> : ""
                        }
                        <Link to="/">{i18next.t('validation.6')}</Link>
                    </div>

                    <div className="validation-item-content">
                        {
                            paypalStatus === "success" ? <h4>{i18next.t('validation.7')}</h4> : ""
                        }
                        <div className="dflex justify-content-between">
                            <Link className="validation-item--parent"
                                  to={getRouteByMenuKey(menuList, "notre_savoir_faire").route}>
                                <div className="validation-item">
                                    <img
                                        src={`${BASE_API_GATEWAY}${getDataBySectionAndWidget(1, 1, data, "PHOTO") && getDataBySectionAndWidget(1, 1, data, "PHOTO").value}`}
                                        alt="validation image"/>

                                    <div className="validation-item-text">
                                        <p dangerouslySetInnerHTML={{__html: getDataBySectionAndWidget(1, 2, data).value}}></p>
                                    </div>
                                </div>
                            </Link>

                            <Link className="validation-item--parent"
                                  to={getRouteByMenuKey(menuList, "nouveaux_precieux").route}>
                                <div className="validation-item">
                                    <img
                                        src={`${BASE_API_GATEWAY}${getDataBySectionAndWidget(2, 1, data, "PHOTO") && getDataBySectionAndWidget(2, 1, data, "PHOTO").value}`}
                                        alt="validation image"/>
                                    <div className="validation-item-text">
                                        <p dangerouslySetInnerHTML={{__html: getDataBySectionAndWidget(2, 2, data).value}}></p>
                                    </div>
                                </div>
                            </Link>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: any): Props => {
    const validationPage = state.menuR.menu.length ? state.menuR.menu.find(item => item.key == "paiement") : {};
    return {
        data: getValidation(state),
        error: getError(state),
        validationPage: validationPage,
        menuList: getMenu(state),
        user: getUser(state),
        userStore: getUserStore(state),
    };
};

const mapDispatchToProps = (dispatch): typeof ValidationActionCreators => {
    return bindActionCreators({
        ...ValidationActionCreators,
        ...AddToCartActionCreators
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ValidationPaypal);
